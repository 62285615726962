<template>
  <div class="page">
    <div class="top_box">
      <div class="left_box">总人数：{{ list.length }}人</div>
      <!-- <div class="right_box">总分数：{{ totalPoints }}分</div> -->
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="sort">{{ index + 1 }}</div>
        <div class="pic_box">
          <img v-if="item.avatar == ''" src="./img/defult_avatar.png" alt="" />
          <img v-else :src="item.avatar" alt="" />
        </div>
        <div class="name">{{ isOwnerFun(item) }}</div>
        <!-- <div class="points">{{ item.points }}</div> -->
        <img
          v-if="isOwnerStatus == 1 && item.userId != userId"
          @click="removeUser(item)"
          src="./img/remove_icon.png"
          class="remove"
        />
      </div>
    </div>
    <v-dialog
      confirmButtonColor="#FE9615"
      v-model="isDialog"
      @confirm="confirm"
    >
      <p class="dialog_text">是否确定移除该用户？</p>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getAssUserByIdUrl, checkIsOwnerUrl, removeMembersUrl } from "./api.js";
export default {
  name: "communityMembers",
  data() {
    return {
      isDialog: false,
      id: null,
      list: [],
      totalPoints: null,
      isOwnerStatus: null,
      removeUserId: null,
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getList();
    this.checkIsOwnerFun();
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    checkIsOwnerFun() {
      //判断当前用户是否为当前社群群主
      let params = {
        userId: this.userId,
        associationId: this.id,
      };
      this.$axios
        .get(`${checkIsOwnerUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.isOwnerStatus = res.data;
            // this.isOwnerStatus = 0
          }
        });
    },
    isOwnerFun(data) {
      //群主名称处理
      if (data.isOwner == "1") {
        return data.nickName + "（群主）";
      } else {
        return data.nickName;
      }
    },
    getList() {
      //获取社群成员列表
      let params = {
        userId: this.userId,
        associationId: this.id,
      };
      this.$axios
        .get(`${getAssUserByIdUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.list = res.data;
            let sum = [];
            this.list.forEach((item) => {
              sum.push(item.points);
            });
            for (let i = 0; i < sum.length; i++) {
              this.totalPoints += sum[i];
            }
          }
        });
    },
    removeUser(data) {
      this.removeUserId = data.userId;
      this.isDialog = true;
    },
    confirm() {
      let params = {
        userId: this.removeUserId,
        associationId: this.id,
      };
      this.$axios
        .post(`${removeMembersUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$toast("移除群成员操作成功！");
            this.getList();
          } else {
            this.$toast(res.msg);
          }
        });
      this.isDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;

  .dialog_text {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 86px 124px 58px;
    white-space: nowrap;
  }

  .list {
    width: 100%;
    padding: 0 45px;
    box-sizing: border-box;

    .item {
      width: 100%;
      height: 130px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      // line-height: 130px;
      display: flex;
      align-items: center;

      .remove {
        width: 30px;
        height: 34px;
      }

      .points {
        text-align: right;
        flex: 1;
        font-size: 40px;
        font-weight: 600;
        color: #fe9615;
      }

      .name {
        flex: 1;
        font-size: 30px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }

      .sort {
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.5);
        margin-left: 24px;
        margin-right: 46px;
      }

      .pic_box {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        margin-right: 22px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .top_box {
    padding: 0 70px;
    box-sizing: border-box;
    width: 100%;
    height: 86px;
    background: #fafafa;
    font-size: 26px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 86px;
    display: flex;

    .left_box {
      flex: 1;
    }

    .right_box {
      flex: 1;
      text-align: right;
    }
  }
}
</style>
